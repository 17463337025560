import { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { AddAccountKlarnaContext } from "../../AddAccountKlarnaContext";
import { ADD_EXTERNAL_KLARNA_URL } from "../../AddAccountKlarnaStory";
import { TinkAccount } from "../../../../../data/dataTink";
import { AccountLoading } from "../../../../../components/tink/accountLoading/AccountLoading";

interface Props {
  next: () => void;
}

export function AccountLoadingWrapper({ next }: Props) {
  const tinkContext = useContext(AddAccountKlarnaContext);
  const bank = tinkContext.state.tinkBank;
  const setState = tinkContext.setState;

  const onComplete = useCallback(
    (accounts: TinkAccount[], accountVerificationReportId: string) => {
      setState({ tinkAccounts: accounts, accountVerificationReportId });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  if (!bank) {
    return <Redirect to={getNavLink(ADD_EXTERNAL_KLARNA_URL)} />;
  }
  return <AccountLoading bank={bank} onComplete={onComplete} />;
}
