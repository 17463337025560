import {
  Button,
  Card,
  SNACKBAR_TYPES,
  Snackbar,
  Typography,
} from "@lysaab/ui-2";
import { useContext, VoidFunctionComponent } from "react";
import { Link } from "react-router-dom";
import { MoveType } from "../../../../../../data/dataDanica";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { useIsPerson } from "../../../../../../hooks/useIsPerson";
import { useIsSignedIn } from "../../../../../../hooks/useIsSignedIn";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";
import { MIGRATION_KF_INFO_PAGE_URL } from "../info/MigrationInfo";
import { MIGRATION_KF_INFO_SHARES_PAGE_URL } from "../info/MigrationInfoShares";
import { CompanyUserList } from "./CompanyUserList";
import { KfMigrationContext } from "./KfMigrationContext";

interface Props {
  next: () => void;
}

export const Intro: VoidFunctionComponent<Props> = ({ next }) => {
  const kfWithdrawalContext = useContext(KfMigrationContext);
  const kfWithdrawalState = kfWithdrawalContext.state;
  const isPerson = useIsPerson();
  const isSignedIn = useIsSignedIn();

  if (isPerson) {
    return (
      <div>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          Denna tjänst är enbart tillgänglig för företagskunder
        </Snackbar>
        <CompanyUserList />
      </div>
    );
  }

  if (!isSignedIn) {
    return (
      <div>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          Du måste vara inloggad för att kunna använda denna tjänst
        </Snackbar>
        <Button
          component={Link}
          to={getNavLink(LOGIN_SWEDEN_PAGE_URL)}
          label="Logga in"
          block
        />
      </div>
    );
  }

  return (
    <div className="withdrawal-migration-page-intro">
      <Typography type="h1">
        Flytta dina kapitalförsäkringar till Lysa Life <br /> Samarbetet med
        Futur Pension avslutas 2025
      </Typography>
      <p>
        I våras lanserade vi vårt nya och egna försäkringsbolag. Detta har gett
        oss helt nya möjligheter att bredda produkterbjudandet och skapa ännu
        mer värde för dig som företagskund. Att flytta över
        kapitalförsäkring(ar) från vår tidigare försäkringsgivare (Futur
        Pension) till Lysa Life är enkelt. Klicka på knappen nedan för att komma
        igång.
      </p>
      <Card>
        <ul>
          <li>
            Svara på några frågor, legitimera dig som firmatecknare och skicka
            en begäran om flytt med ditt mobila BankID - så sköter vi hela
            flytten åt dig.
          </li>
          <li>
            Din nuvarande kapitalförsäkring hos Futur Pension återköps och
            ersätts med en nytecknad försäkring hos Lysa Life med samma
            investeringsinriktning och fördelning som tidigare.
          </li>
          <li>
            Om du genomför flytten innan 01/12-2024 så bjuder vi på
            försäkringsavgiften (0,2 %) under hela 2025.
          </li>
          <li>
            Om du inte genomför flytten, kommer vi att behöva avsluta
            försäkringen då vårt samarbete med Futur Pension upphör inför våren
            2025.{" "}
          </li>
          {kfWithdrawalState.moveType === MoveType.SHARES && (
            <li>
              Flytten innebär att fondandelarna tillfälligt förs över till en
              värdepappersdepå över årsskiftet, för att direkt efter årsskiftet
              flyttas till din nya försäkring hos Lysa Life.
            </li>
          )}
        </ul>
        <p className="no-m-b">
          {kfWithdrawalState.moveType === MoveType.SHARES && (
            <Link to={getNavLink(MIGRATION_KF_INFO_SHARES_PAGE_URL)}>
              Läs mer här
            </Link>
          )}
          {kfWithdrawalState.moveType === MoveType.CASH && (
            <Link to={getNavLink(MIGRATION_KF_INFO_PAGE_URL)}>Läs mer här</Link>
          )}
        </p>
      </Card>
      <Snackbar type={SNACKBAR_TYPES.INFO}>
        *Erbjudandet kommer att vara tillgängligt fram till den{" "}
        <strong>20/12 2024</strong>
      </Snackbar>
      <Button
        block
        label="Nästa"
        onClick={() => {
          next();
        }}
      />
    </div>
  );
};
