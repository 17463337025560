import { FunctionComponent } from "react";

import { NewIcon, Tickbox, Typography } from "@lysaab/ui-2";
import {
  Information,
  InsurelyPensionType,
} from "../../../../../../data/dataLifePensionMove";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { PensionLogo } from "../../../../../../components/pensionLogo/PensionLogo";

import "./MoveCard.scss";
import { AccountType } from "../../../../../../data/dataAccounts";
import { Amount } from "../../../../../../components/amount/Amount";

interface Props {
  insuranceCompany?: string;
  insuranceNumber?: string;
  insuranceHolderName?: string;
  insuranceHolderTin?: string;
  currentWorth?: number;
  information?: Information;
  checked?: boolean;
  handleChange?: () => void;
  type?: AccountType | InsurelyPensionType;
}

export const MoveCard: FunctionComponent<Props> = ({
  insuranceCompany,
  insuranceNumber,
  insuranceHolderName,
  insuranceHolderTin,
  currentWorth,
  information,
  checked,
  handleChange,
  type,
}) => {
  if (!insuranceCompany) {
    return null;
  }

  return (
    <section className="potential-move-card">
      <header className="potential-move-card-header">
        <div className="potential-move-card-header-company">
          <PensionLogo pension={{ key: insuranceCompany }} />
          <div className="potential-move-card-header-company-name-and-worth">
            <Typography
              type="label-large"
              className="potential-move-card-institute"
            >
              {getReadableName(insuranceCompany)}
            </Typography>
            {currentWorth && currentWorth === -1 && (
              <TranslatedText
                id={"sweden.transfer-pension.move-card.currentWorth.unkown"}
              />
            )}
            {currentWorth && currentWorth !== -1 && (
              <Amount amount={currentWorth} />
            )}
          </div>
        </div>
        {handleChange && (
          <Tickbox
            size={18}
            checked={checked ? checked : false}
            alternative={{
              text: "",
              value: insuranceCompany,
            }}
            onChange={() => handleChange()}
            validators={[]}
          />
        )}
      </header>
      {(insuranceNumber || insuranceHolderName || information) && <hr />}
      {insuranceNumber && (
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.move-card.insuranceNumber.label"}
            />
          </dt>
          <dd>{insuranceNumber}</dd>
        </dl>
      )}
      {(insuranceHolderName || insuranceHolderTin) &&
        type !== "PRIVATE_PENSION" && (
          <dl>
            <dt>
              <TranslatedText
                id={
                  "sweden.transfer-pension.move-card.insuranceHolderName.label"
                }
              />
            </dt>
            <dd>
              {insuranceHolderName ? insuranceHolderName : insuranceHolderTin}
            </dd>
          </dl>
        )}
      {type && type === AccountType.LYSA_PPF && (
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.move-card.ppf.label"}
            />
          </dt>
        </dl>
      )}
      {information && (
        <ul className="information-list">
          {Object.entries(information)
            .sort(warningsFirstCompareFn)
            .map(([key, severity]) => {
              return (
                <li className="information" key={key}>
                  {severity === "INFO" ? (
                    <NewIcon.Information
                      className="icon"
                      size={64}
                      primaryColor="var(--lysa-text-secondary)"
                    />
                  ) : (
                    <NewIcon.Warning
                      className="icon"
                      size={64}
                      primaryColor="var(--lysa-text-secondary)"
                    />
                  )}
                  <Typography type="body-small" className="information-message">
                    <InformationMessage messageKey={key} />
                  </Typography>
                </li>
              );
            })}
        </ul>
      )}
    </section>
  );
};

export const getReadableName = (name: string) => {
  if (name === "AMF") {
    return "AMF";
  }
  if (name === "ALECTA") {
    return "Alecta";
  }
  if (name === "AVANZA") {
    return "Avanza";
  }
  if (name === "FOLKSAM") {
    return "Folksam";
  }
  if (name === "FUTUR") {
    return "Futur";
  }
  if (name === "HANDELSBANKEN") {
    return "Handelsbanken";
  }
  if (name === "KPA") {
    return "KPA";
  }
  if (name === "LANSFORSAKRINGAR") {
    return "Länsförsäkringar";
  }
  if (name === "NORDEA") {
    return "Nordea";
  }
  if (name === "NORDNET") {
    return "Nordnet";
  }
  if (name === "MOVESTIC") {
    return "Movestic";
  }
  if (name === "SEB") {
    return "SEB";
  }
  if (name === "SKANDIA") {
    return "Skandia";
  }
  if (name === "SPP") {
    return "SPP";
  }
  if (name === "SVENSKA_FRIBREVSBOLAGET") {
    return "Svenska Fribrevsbolaget";
  }
  if (name === "SWEDBANK") {
    return "Swedbank";
  }
  return name;
};

const InformationMessage = ({ messageKey }: { messageKey: string }) => {
  if (messageKey === "INSURED_NAME_MISMATCH") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.insured-name-mismatch"}
      />
    );
  }
  if (messageKey === "NOT_SUPPORTED_TYPE") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.not-supported-type"}
      />
    );
  }
  if (messageKey === "ACTIVE_PAYMENT") {
    return (
      <TranslatedText id={"sweden.transfer-pension.move-card.active-payment"} />
    );
  }
  if (messageKey === "ACTIVE_PAYOUT") {
    return (
      <TranslatedText id={"sweden.transfer-pension.move-card.active-payout"} />
    );
  }
  if (messageKey === "MISSING_INSURANCE_HOLDER_DATA") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.missing-insurance-holder-data"}
      />
    );
  }
  if (messageKey === "NO_RESULT") {
    return (
      <TranslatedText id={"sweden.transfer-pension.move-card.no-result"} />
    );
  }
  if (messageKey === "TRADITIONALLY_MANAGED") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.traditionally-managed"}
      />
    );
  }
  if (messageKey === "INDIVIDUAL_COMPANY") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.individual-company"}
      />
    );
  }
  if (messageKey === "INSURANCE_NUMBER_MISSING") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.insurance-number-missing"}
      />
    );
  }
  return null;
};

type InfoEntry = [string, "INFO" | "WARNING"];
function warningsFirstCompareFn(a: InfoEntry, b: InfoEntry) {
  if (a[1] === "INFO" && b[1] === "WARNING") {
    return 1;
  }
  if (a[1] === "WARNING" && b[1] === "INFO") {
    return -1;
  }
  return 0;
}
